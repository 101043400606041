

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$white:  #fff;
$black:  #000;
$red:    #d9534f;
$orange: #f0ad4e;
$yellow: #ffd500;
$green:  #5cb85c;
$blue:   #0275d8;
$teal:   #5bc0de;
$pink:   #ff5b77;
$purple: #613d7c;

$gray:       lighten($black, 50%);
$gray-dark:  lighten($black, 20%);
$gray-light: lighten($black, 90%);

$brand-primary:             #279E43;
$brand-success:             #5cb85c;
$brand-info:                #5bc0de;
$brand-warning:             #f0ad4e;
$brand-danger:              #d9534f;
$brand-inverse:             $gray-dark;

// Body
//
// Settings for the `<body>` element.

$body-bg:    #fff !default;
$body-color: $gray-dark !default;


// Links
//
// Style anchor elements.

$link-color:            #428BCA;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;



// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif:      'Dosis', Georgia, "Times New Roman", Times, serif;
$font-family-base:       $font-family-sans-serif;

$headings-font-family:   $font-family-serif;

$h1-font-size: 3.5rem;
