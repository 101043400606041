
.admin {


    #site_footer {
        margin-top: 20px;
        padding: 10px;
    }

    //Form spacing
    .control-group {
        margin-bottom: 10px;
    }
    .md-header {
        .btn-group {
            margin-right: 3px;
        }
    }
}