#site_header {

    background-color: $brand-primary;
    margin-bottom: 2rem;

    .navbar {
        a {
            color: $white;
        }
        .nav-item.active {
            font-weight: bold;
        }

        .navbar-toggleable-sm {
            clear: both;
        }

        border: none;
        font-size: 20px;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
        .container nav {
            padding: 0;
        }
    }

}

#site_footer {
    background-color: $brand-primary;
    color: white;
    margin-top: 2rem;

    a {
        color: white;
    }

    .whoami {
        margin: 10px 0;
        a {
            color: black;
        }
    }
    .smallprint {
        font-size: $font-size-sm;
        margin: 5px 0;
    }
}

