/**
 * Bootstrap-Markdown.less
 *
 * @author Taufan Aditya @taufanaditya
 * @copyright 2013 Taufan Aditya
 */

.md-editor {
  display:block;
  border:1px solid $table-border-color;

  > .md-header, .md-footer {
    display:block;
    padding:6px 4px;
    background:white;
  }

  > .md-preview {
    background:white;
    border-top: 1px dashed $table-border-color;
    border-bottom: 1px dashed $table-border-color;
    min-height:10px;
  }

  > textarea {
    font-family: $font-family-monospace;
    font-size: $font-size-base;
    outline: 0;
    outline: thin dotted \9; /* IE6-9 */
    margin:0;
    display:block;
    padding:0;
    width:100%;
    border:0;
    border-top: 1px dashed $table-border-color;
    border-bottom: 1px dashed $table-border-color;
    border-radius: 0;
    box-shadow:none;
    background:$input-disabled-bg;
    &:focus {
      box-shadow:none;
      background:$input-bg;
    }
  }
 
  // Hover state
  &.active {
    @include box-shadow("inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6)");
    border-color: rgba(82,168,236,.8);
    outline: 0;
    outline: thin dotted \9; /* IE6-9 */
    @include transition("border linear .2s, box-shadow linear .2s");
  }
}
