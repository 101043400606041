
#project-grid {

    .project-listing {

        a {
            color: white;
            text-decoration: none;
            &:hover {
                text-decoration: none !important;
            }
        }

        h3 {
            margin: 0;
            padding: 10px;
            background-color: rgba(0, 0, 0, 1);
            color: white;
        }

        .inner {
            height: 400px;
            position: relative;
            overflow: hidden;
            //width:400px;
            //min-height: 350px;

            margin-bottom: 15px;
            background-color: white;
            background-repeat: no-repeat;
            background-size: cover;



            .intro {
                position: absolute;
                bottom:0;
                background-color: rgba(0, 0, 0, 0.70);
                color: white;
                padding: 10px;
                width: 100%;
            }
            &:hover {
                .intro {
                     -webkit-transform: rotateX( 0deg );
                     -moz-transform: rotateX( 0deg );
                     -o-transform: rotateX( 0deg );
                     transform: rotateX( 0deg );
                }
            }
        }
    }
}


//Tiny devices
#project-grid {
    .project-listing {

        &.tiny, &.small, &.medium, &.large {
            width:100%;
            //width:375px;
        }
    }
}
// Small device columns (phones to tablets)
@media (min-width: map-get($grid-breakpoints, 'md')) {
    #project-grid {
        .project-listing {

            &.tiny, &.small {
                width:50%;
            }
            &.medium, &.large {
                width:100%;
            }
        }
    }
}

// Medium and large device columns (desktop and up)
@media (min-width: map-get($grid-breakpoints, 'lg')) {
    #project-grid {
        .project-listing {

            &.tiny {
                width:323px;
            }
            &.small {
                width:323px;
            }
            &.medium {
                width:646px;
            }
            &.large {
                width:646px;
            }
        }
    }
}

// Large desktops and up
@media (min-width: map-get($grid-breakpoints, 'xl')) {
    #project-grid {
        .project-listing {

            &.tiny {
                width:300px;
            }
            &.small {
                width:300px;
            }
            &.medium {
                width:600px;
            }
            &.large {
                width:600px;
            }
        }
    }
}

.project {
    .carousel {
        margin-bottom: 20px;
    }
}
