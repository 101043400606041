.home {
    position: relative;

    p {
        max-width: 900px;
    }

    h1 {
        color: darken($brand-primary, 10%);
    }

    .section_heading {
        font-weight: 400;
        color: $body-color;
    }

    .blog-posts {
        .post {
            position: relative;
            margin-bottom: 20px;
            min-height: 100px;
            .overlay {
                position: absolute;
                width: 100%;
                top:0;
                left:0;
                background: linear-gradient(#000, rgba(0, 0, 0, 0));
                padding-bottom: 20px;
            }
            h3 {
                margin: 10px;
                a {
                    color: white;
                }
            }
            .date {
                margin-left: 10px;
                color: $gray-light;
            }
        }
    }

    .portfolio {
        .project {
            position: relative;
            margin-bottom: 20px;
            min-height: 200px;
            .overlay {
                position: absolute;
                width: 100%;
                top:0;
                left:0;
                background: linear-gradient(#000, rgba(0, 0, 0, 0));
                color: white;
                padding: 10px 10px 50px;
            }
            h2 {
                margin: 0 0 10px;
                a {
                    color: white;
                }
            }
        }

    }
}
