.blog {
  .categories {
    a.active {
      font-weight: bold;
    }
  }
  .post-listing {
    h3 {
      margin: 5px 0;
    }
    .date {
      color: $gray;
      font-size: $font-size-sm;
    }
    img {
      min-height: 100px;
    }
  }

  .post {
    margin-top: -20px;

    .post-header {
      position: relative;
      margin-bottom: 10px;
      min-height:110px;
      overflow: hidden;
      img {
        max-width: 100%;
        height: auto;
        min-height: 100px;
        vertical-align: middle;
        border: 0;
        width: auto\9;
      }
    }
    .breadcrumb {
      margin: 0;
      position: absolute;
      top: 10px;
      left: 10px;
      li.active {
        display: inline;
      }
    }
    h1 {
      font-size: $font-size-lg * 1.5;
    }
    .meta-data {
      margin-bottom: 10px;
      color: $gray;
      font-size: $font-size-sm;
      .left {
        float: left;
      }
      .social {
        display: inline-block;
        float:right;
        min-width:300px;
        .google {
          display: inline-block;
        }
      }
    }
  }
}
// Small device columns (phones to tablets)
@media (min-width: map-get($grid-breakpoints, 'md')) {
  .blog {
    .post {
      h1 {
        display: inline;
        background-color: white;
        padding: 2px 10px;
        position: absolute;
        bottom:25px;
        left:10px;
        margin: 0;
        font-size: $font-size-lg * 1.75;
      }
      .post-header {
        margin-top: 0px;
        height: 225px;
        overflow: hidden;
      }
    }
  }
}

// Medium and large device columns (desktop and up)
@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .blog {
    .post {
      h1 {
        display: inline;
        background-color: white;
        padding: 2px 10px;
        position: absolute;
        bottom:10px;
        left:10px;
        margin: 0;
        font-size: $font-size-lg * 2;
      }
      .post-header {
        margin-top: 0px;
        height: 275px;
        overflow: hidden;
      }
    }
  }
}

// Large desktops and up
@media (min-width: map-get($grid-breakpoints, 'xl')) {
  .blog {
    .post {
      h1 {
        display: inline;
        background-color: white;
        padding: 2px 10px;
        position: absolute;
        bottom:25px;
        left:10px;
        margin: 0;
        font-size: $font-size-lg * 2;
      }
      .post-header {
        margin-top: 0px;
        height: 350px;
        overflow: hidden;
      }
    }
  }
}
